(function ($) {
  /**
   * Описание объекта
   */
  var cart = function () {
    return cart.init.apply(this, arguments);
  };

  /**
   * Расширение объекта
   */
  $.extend(cart, {
    /**
     * Настройки по умолчанию
     */
    options: {
      routes: {
        index: '/cart',
        add: '/cart/add',
        remove: '/cart/remove',
        inc: '/cart/inc',
        dec: '/cart/dec',
        quantity: '/cart/quantity',
      },
    },
    /**
     * Инициализация
     * @param options
     */
    init(options) {
      this.options = $.extend(this.options, options);

      this.bind();

      return this;
    },
    /**
     * "Навешиваем" события
     */
    bind() {
      const me = this;

      $(document).on('click', '[data-to-cart]', function (e) {
        e.preventDefault();
        const target = e.target.closest('[data-to-cart]');
        target.classList.add('_load');
        setTimeout(() => {
          target.classList.remove('_load');
        }, 4000);

        const $button = $(this);
        const $toCartBlock = $button.closest('[data-to-cart-block]');
        if ($toCartBlock.length) {
          const $offerInput = $toCartBlock.find('[data-offer-id]');
          me.add($offerInput.val(), 1);
        }
        return false;
      });

      $(document).on('click', '[data-cart-dec]', function (e) {
        e.preventDefault();
        const $button = $(this);
        me.dec($button.data('cart-dec'));
        return false;
      });

      $(document).on('click', '[data-cart-inc]', function (e) {
        e.preventDefault();
        const $button = $(this);
        me.inc($button.data('cart-inc'));
        return false;
      });

      $(document).on('click', '[data-cart-remove]', function (e) {
        e.preventDefault();
        const $button = $(this);
        me.remove($button.data('cart-remove'));
        return false;
      });
    },
    add(id, quantity) {
      this.request(this.options.routes.add, {
        id,
        quantity,
      });
    },
    dec(position) {
      this.request(this.options.routes.dec, {
        position,
      });
    },
    inc(position) {
      this.request(this.options.routes.inc, {
        position,
      });
    },
    remove(position) {
      this.request(this.options.routes.remove, {
        position,
      });
    },
    request(route, request, callback) {
      const me = this;
      $.ajax({
        url: route,
        dataType: 'json',
        type: 'post',
        data: request,
        success(data) {
          if (data.status && data.message) {
            me.message(status, data.message);
          }
          if (data.status == 'success') {
            me.update();
          }
          if (callback) {
            callback();
          }
        },
      });
    },
    /**
     * @param status "success"|"error"
     * @param message
     */
    message(status, message) {
      const successBlock = document.querySelector('[data-cart-success]');
      if (!successBlock) {
        return;
      }
      const messageBlock = successBlock.querySelector('[data-message]');
      if (messageBlock) {
        messageBlock.innerHTML = message;
      }


      successBlock.classList.add('_show');

      const successTimer = setTimeout(() => {
        successBlock.classList.remove('_show');
      }, 4000);

      successBlock.querySelector('[data-close-success]').addEventListener('click', () => {
        clearTimeout(successTimer);
        successBlock.classList.remove('_show');
      });
    },
    update() {
      const me = this;
      const $blocks = $('[data-cart-block]');
      if ($blocks.length > 0) {
        $blocks.addClass('loading');
        $.ajax({
          success(page) {
            const $page = $(page);
            me.updateBlocks($page);
          },
        });
      }
    },
    updateCart() {
      const me = this;
      $.ajax({
        method: 'get',
        data: $('#cart-order').serialize(),
        success(page) {
          const $page = $(page);
          me.updateBlocks($page);
        },
      });
    },
    updateBlocks($page) {
      $page.find('[data-cart-block]').each(function () {
        const $newBlock = $(this);
        const name = $newBlock.data('cart-block');
        const $block = $(`[data-cart-block="${name}"]`);
        $block.removeClass('loading');
        $block.html($newBlock.html());
      });
    },
  });

  /**
   * Инициализация функции объекта для jQuery
   */
  return $.cart = function (options) {
    return cart.init(options);
  };
}($));

// $(function(){
//	$('body').cart();
// });

$.cart();