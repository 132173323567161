import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';
import LiveEvent from "../../components/live/live";

function setActiveThumb(index) {
  document.querySelectorAll('[data-thumb]').forEach((th) => { th.classList.remove('_active'); });
  document.querySelector(`[data-thumb="${index}"]`).classList.add('_active');
}

if (document.querySelector('[data-product-slider]')) {
  const slider = tns({
    container: '[data-product-slider]',
    items: 1,
    slideBy: '1',
    autoplay: false,
    mouseDrag: true,
    responsive: {
      768: {
        fixedWidth: 568,
        gutter: 4,
      },
      1192: {
        fixedWidth: false,
        gutter: 0,
      },
    },
  });

  slider.events.on('indexChanged', () => {
    setActiveThumb(slider.getInfo().navCurrentIndex);
  });

  document.querySelectorAll('[data-thumb]').forEach((thumb) => {
    thumb.addEventListener('click', () => {
      const index = parseInt(thumb.dataset.thumb, 10);
      slider.goTo(index);
    });
  });
}

if (document.querySelector('[data-suggestions]')) {
  let initialized = false;
  const border = 1192;
  let slider = null;

  const check = () => {
    const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (viewPort >= border && !initialized) {
      initialized = true;
      slider = tns({
        container: '[data-suggestions]',
        items: 4,
        slideBy: 'page',
        autoplay: false,
        mouseDrag: true,
        nav: false,
        controls: false,
        disable: true,
        responsive: {
          0: {
            disable: true,
          },
          1192: {
            disable: false,
            fixedWidth: false,
            gutter: 24,
          },
        },
      });
    } else if ((viewPort < border) && initialized) {
      initialized = false;
      if (slider) {
        slider.destroy();
      }
    }
  };
  window.addEventListener('resize', check);
  check();

  document.querySelector('[data-suggest-prev]').addEventListener('click', (e) => {
    e.preventDefault();
    if (slider) {
      slider.goTo('prev');
    }
  });

  document.querySelector('[data-suggest-next]').addEventListener('click', (e) => {
    e.preventDefault();
    if (slider) {
      slider.goTo('next');
    }
  });
}

function renderPrices(price, oldPrice, priceRr) {
  const container = document.querySelector('[data-prices]');
  if (container.dataset.opt === undefined) {
    container.innerHTML = '';
    const priceEl = document.createElement('span');
    priceEl.classList.add('product__price');
    if (oldPrice) {
      priceEl.classList.add('product__price_action');
    }
    priceEl.innerHTML = price;
    container.appendChild(priceEl);

    if (oldPrice) {
      const oldPriceEl = document.createElement('span');
      oldPriceEl.classList.add('product__old-price');
      oldPriceEl.innerHTML = oldPrice;
      container.appendChild(oldPriceEl);
    }
  } else {
    const priceOpt = container.querySelector('[data-product-price]');
    priceOpt.innerHTML = price;
    const priceRREl = container.querySelector('[data-product-price-rr]');
    priceRREl.innerHTML = priceRr;
  }
}

const setOfferFastOrderLink = (offerId) => {
  const fastOrderProductContainer = document.querySelector('[data-fast-order-product]');
  const linkToCart = fastOrderProductContainer.closest('[data-to-cart-block]').querySelector('[data-to-cart="large"]');
  const fastOrderOfferLink = document.querySelector('[data-fast-order-link]');

  fastOrderProductContainer.classList.add('_offer');
  if (fastOrderProductContainer) {
    let href = fastOrderOfferLink.getAttribute('href');
    href = href.slice(0, href.lastIndexOf('/') + 1) + offerId;
    fastOrderOfferLink.setAttribute('href', href);
  }

  if (linkToCart) {
    let href = linkToCart.getAttribute('href');
    href = href.slice(0, href.lastIndexOf('/') + 1) + offerId;
    linkToCart.setAttribute('href', href);
  }
};

function setActiveOffer(offerId) {
  const currentOffer = document.querySelector(`[data-offer="${offerId}"]`);
  const avail = parseInt(currentOffer.dataset.avail, 10);
  let textAvail = currentOffer.dataset.avail;
  let preorder = false;
  if (avail === 0) {
    textAvail = currentOffer.dataset.preorder ? currentOffer.dataset.preorder : 'Под заказ';
    preorder = true;
  }
  if (avail > 5) {
    textAvail = currentOffer.dataset.alotof ? currentOffer.dataset.alotof : 'Много';
  }

  setOfferFastOrderLink(offerId);

  document.querySelector('[data-offer-id]').value = offerId;
  document.querySelectorAll('[data-offer]').forEach((th) => { th.classList.remove('_active'); });
  currentOffer.classList.add('_active');

  document.querySelector('[data-avail]').innerHTML = textAvail;
  const availBlock = document.querySelector('[data-avail-block]');
  if (preorder) {
    availBlock.classList.add('_preorder');
  } else {
    availBlock.classList.remove('_preorder');
  }
  renderPrices(currentOffer.dataset.price, currentOffer.dataset.old, currentOffer.dataset.priceRr);
}

if (document.querySelector('[data-offer-id]')) {
  const offers = document.querySelectorAll('[data-offer]');
  for (let i = 0; i < offers.length; i++) {
    const el = offers[i];
    if (parseInt(el.dataset.avail, 10) > 0) {
      setActiveOffer(el.dataset.offer);
      break;
    }
    if(i === offers.length - 1) {
      setActiveOffer(offers[0].dataset.offer);
    }
  }
  offers.forEach((el) => {
    el.addEventListener('click', () => {
      setActiveOffer(el.dataset.offer);
    });
  });
}

if (document.querySelector('[data-avail-block]')) {
  const availBlock = document.querySelector('[data-avail-block]');
  document.querySelector('[data-avail-block]').addEventListener('click', () => {
    availBlock.classList.toggle('_show');
  });
  document.querySelector('[data-avail-toggle]').addEventListener('click', () => {
    availBlock.classList.toggle('_show');
  });
}

new LiveEvent('click', '[data-modal-closer]', (e) => {
  document.querySelector('.modal__closer').dispatchEvent(new Event('click'));
});