function openPopup() {
  const windowWidth = $(window).width();
  if (windowWidth > 1191) {
    const target = document.querySelector('[data-present]');
    const isPopupSee = sessionStorage.getItem('isPopupSee');
    if (target && !document.querySelector('body').classList.contains('modal-opened') && !isPopupSee) {
      target.dispatchEvent(new Event('click'));
      sessionStorage.setItem('isPopupSee', 'isPopupSee');
    }
  }
}

function mouseLeave() {
  document.addEventListener('mouseleave', openPopup);
}

document.addEventListener('DOMContentLoaded', mouseLeave);