import LiveEvent from '../../components/live/live.js';

const selector = '[data-floating-field] input, [data-floating-field] textarea';

const handler = function floatingFieldHandler() {
  if (this.value) {
    this.parentNode.classList.add('_filled');
  } else {
    this.parentNode.classList.remove('_filled');
  }
};

const handlerReset = function floatingFieldHandlerReset() {
  setTimeout(() => {
    this.querySelectorAll(selector).forEach(el => handler.call(el));
  },10);
};

new LiveEvent('keyup', selector, handler);
new LiveEvent('reset', 'form', handlerReset);