import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    closeOnSuccess: false,
    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if(link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
      }
    }
  });
  return false;
});

new LiveEvent('click', '[data-sizes-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    theme: 'sizes',
    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if(link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
      }
    }
  });
  return false;
});