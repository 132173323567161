function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
}

function checkAndClickCookieLink() {
  const hasConsent = document.cookie.includes('cookie_consent=true');
  if (!hasConsent) {
    const cookieWrapper = document.querySelector('[data-modal-cookie]');
    if (cookieWrapper) {
      cookieWrapper.style.display = 'flex';
      const button = cookieWrapper.querySelector('[data-cookie-submit]');
      button.addEventListener('click', () => {
        setCookie('cookie_consent', 'true', 365);
      });
    }
  }
}

window.addEventListener('DOMContentLoaded', checkAndClickCookieLink);