require('../scss/app.scss');

window.noZensmooth = true;

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./common/ajax_redirect.js');
require('./common/need-animation.js');
require('./common/floating-field');

require('./field/phone.js');
require('../components/smart-tabs/smart-tabs');

require('./cart.js');
require('./depend.js');
require('./pages/product.js');
require('./pages/index.js');
require('./address.js');
require('./popup.js');
require('./cookie');