import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

if (document.querySelector('[data-main-slider]')) {
  const sliderElement = document.querySelector('[data-main-slider]');
  const slider = tns({
    container: sliderElement,
    items: 1,
    mode: 'gallery',
    slideBy: 'page',
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayButtonOutput: false,
    mouseDrag: true,
    nav: false,
  });
}