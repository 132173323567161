import 'suggestions-jquery/dist/js/jquery.suggestions';
import 'suggestions-jquery/dist/css/suggestions.css';
import $ from 'jquery';

window.addEventListener('load', () => {
  $('[data-address]').closest('.floating-field').addClass('_filled-force');
  $('[data-address]').suggestions({
    token: 'e8b235fdd38ca32fa42c3e12a0ef6fc5f8afd1a3',
    type: 'ADDRESS',
    /* Вызывается, когда пользователь выбирает одну из подсказок */
    onSelect(suggestion) {
      console.log(suggestion);
    },
  });
});